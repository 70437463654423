<template lang="pug">
span
  button.commands-indicator-button(@click='isCommandsModalActive = true')
    icon(icon='paper-plane' :style='{ color: "white" }')
    span.pending-count(v-if='pendingCommands.length') {{ pendingCommands.length }}
  b-modal(v-model='isCommandsModalActive' has-modal-card)
    modal-card(title='Comandos en curso')
      commands-list.commands-list
</template>

<script>
import { mapState } from 'vuex'
import CommandsList from './CommandsList'

export default {
  components: { CommandsList },
  data () {
    return {
      isCommandsModalActive: false
    }
  },
  computed: {
    ...mapState({
      pendingCommands: state => state.commands.pendingCommands
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';

.commands-indicator-button {
  border: none;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: inherit;
  position: relative;
}

.pending-count {
  position: absolute;
  right: 0;
  top: 0;
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
