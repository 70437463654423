import eventsService from '@/services/events.service'
import reportTypesService from '@/services/report-types.service'

const state = () => ({
  loading: false,
  error: null,
  events: [],
  reportTypes: [],
  reportTypesMessages: {},
  deviceNames: {},
  limit: 100,
  offset: 0
})

const normalizeEvent = (event, deviceNames, reportTypesMessages) => {
  event.loading = false
  if (!event.deviceName) {
    event.deviceName = deviceNames[event.deviceId]
  }
  if (!event.message && event.type) {
    event.message = reportTypesMessages[event.type]
  }
  return event
}

const mutations = {
  setEvents: (state, events) => {
    state.events = events
  },
  setDeviceNames: (state, deviceNames) => {
    state.deviceNames = deviceNames
  },
  setStatus: (state, { loading, error }) => {
    state.loading = loading
    state.error = error
  },
  setReportTypes: (state, reportTypes) => {
    state.reportTypes = reportTypes
  },
  setReportTypesMessages: (state, reportTypesMessages) => {
    state.reportTypesMessages = reportTypesMessages
  },
  setPaginationVariables: (state, { limit, offset }) => {
    state.limit = limit
    state.offset = offset
  }
}

const actions = {
  getEvents: async ({ commit, state }, params) => {
    commit('setStatus', { loading: true })
    const eventParams = { ...(params || {}), limit: state.limit, offset: state.offset }
    const [eventsResult, reportTypesResult] = await Promise.all([
      eventsService.getAll(eventParams),
      reportTypesService.getAll()
    ])

    if (eventsResult && reportTypesResult) {
      const reportTypesMessages = reportTypesResult.reduce((obj, type) => {
        obj[type.id] = type.message
        return obj
      }, {})
      commit('setReportTypes', reportTypesResult)
      commit('setReportTypesMessages', reportTypesMessages)
      const events = eventsResult.events.map(event =>
        normalizeEvent(event, eventsResult.deviceNames, reportTypesMessages)
      )
      commit('setEvents', events)
      commit('setDeviceNames', eventsResult.deviceNames)
      commit('setStatus', { loading: false })
    } else {
      commit('setEvents', [])
      commit('setStatus', { loading: false, error: 'Error cargando events TODO' })
    }
  },
  getMoreEvents: async ({ commit, state }) => {
    commit('setStatus', { loading: true })
    let { limit, offset } = state
    offset += limit
    const [eventsResult] = await Promise.all([
      eventsService.getAll({ limit, offset })
    ])

    if (eventsResult) {
      const events = eventsResult.events.map(event =>
        normalizeEvent(event, eventsResult.deviceNames, state.reportTypesMessages)
      )
      commit('setEvents', [...state.events, ...events])
      const deviceNames = {
        ...state.deviceNames,
        ...eventsResult.deviceNames
      }
      commit('setDeviceNames', deviceNames)
      commit('setPaginationVariables', { offset, limit })
      commit('setStatus', { loading: false })
    } else {
      commit('setEvents', [])
      commit('setStatus', { loading: false, error: 'Error cargando events TODO' })
    }
  }
}

const getters = {
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
