import commandsService from '@/services/commands.service'

const state = () => ({
  isLoading: false,
  isSaving: false,
  isError: false,
  pendingCommands: [],
  commands: [],
  savingLoading: false,
  error: null
})

const mutations = {
  setCommands: (state, commands) => {
    state.commands = commands
  },
  setCommand: (state, command) => {
    const currentPendingCommand = state.pendingCommands.find(c => c.id === command.id)
    if (currentPendingCommand) {
      Object.assign(currentPendingCommand, command)
    }
    const currentCommand = state.commands.find(c => c.id === command.id)
    if (currentCommand) {
      Object.assign(currentCommand, command)
    }
  },
  setPendingCommands: (state, commands) => {
    state.pendingCommands = commands
  },
  appendPendingCommands: (state, commands) => {
    state.pendingCommands.unshift(...commands)
  },
  setStatus: (state, { isLoading, isError }) => {
    state.isLoading = isLoading
    state.isError = isError
  },
  setSavingStatus: (state, { loading, error }) => {
    state.savingLoading = loading
    state.error = error
  }
}

const actions = {
  loadPendingCommands: async ({ commit }, minId) => {
    commit('setStatus', { isLoading: true })
    const result = await commandsService.getAll({ onlyPending: true, minId })
    if (result) {
      if (minId) {
        commit('appendPendingCommands', result)
      } else {
        commit('setPendingCommands', result)
      }
      commit('setStatus', { isLoading: false })
    } else {
      commit('setStatus', { isLoading: false, isError: true })
    }
  },
  loadCommands: async ({ commit }) => {
    commit('setStatus', { isLoading: true })
    const result = await commandsService.getAll()
    if (result) {
      commit('setCommands', result)
      commit('setStatus', { isLoading: false })
    } else {
      commit('setCommands', [])
      commit('setStatus', { isLoading: false, isError: true })
    }
  },
  update: async ({ commit }, command) => {
    commit('setCommand', command)
  },
  save: async ({ commit, dispatch }, command) => {
    try {
      commit('setSavingStatus', { loading: true })
      const result = await commandsService.save(command)
      commit('setSavingStatus', { loading: false })
      dispatch('loadPendingCommands')
      return result
    } catch (error) {
      commit('setSavingStatus', { loading: false, error })
    }
  }
}

const getters = {
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
