<template lang="pug">
.command-list
  div(v-if='showPendingCommands')
    command-list-item(v-for='command in pendingCommands' :key='command.id' :command='command')
    i(v-if='pendingCommands.length === 0') No hay comandos en curso.
    hr
    b-button(type='is-primary' size='is-small' @click='loadAllComands') Mostrar toda la lista de comandos
  div(v-else)
    command-list-item(v-for='command in commands' :key='command.id' :command='command')
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CommandListItem from './CommandListItem'

export default {
  components: { CommandListItem },
  data () {
    return {
      showPendingCommands: true
    }
  },
  created () {

  },
  computed: {
    ...mapState({
      isLoading: state => state.commands.isLoading,
      isSaving: state => state.commands.isSaving,
      isError: state => state.commands.isError,
      commands: state => state.commands.commands,
      pendingCommands: state => state.commands.pendingCommands
    })
  },
  methods: {
    ...mapActions({
      loadCommands: 'commands/loadCommands'
    }),
    loadAllComands () {
      this.showPendingCommands = false
      this.loadCommands()
    }
  }
}
</script>

<style lang="scss" scoped>
.command-list {
  width: 100%;
  max-width: 500px;

  @media only screen and (min-width: 769px) {
    min-width: 500px;
  }
}
</style>
