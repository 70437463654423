<template lang="pug">
b-field(:label='label' label-position='on-border')
  b-datetimepicker(
    horizontal-time-picker
    icon-right-clickable
    locale='es-MX'
    :placeholder='placeholder'
    :icon='icon'
    :icon-right="rawValue ? 'close-circle' : ''"
    :datepicker="{ showWeekNumber: false }"
    :timepicker='{ enableSeconds, hourFormat: "24" }'
    v-model='rawValue'
    @icon-right-click='clearDateTime'
    @input='valueChanged'
  )
</template>

<script>
import { dateToServer } from '@/constants/formats'

const tryToParseValue = value => {
  if (value instanceof Date) return value
  if (typeof value === 'string') return new Date(value)
  return value
}
const formatOutputValue = value => {
  if (value) {
    const formatted = dateToServer(value)
    return formatted
  }
  return value
}
export default {
  props: {
    label: { type: String },
    icon: { type: String },
    type: { type: String, default: 'text' },
    required: { type: Boolean },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    enableSeconds: { type: Boolean },
    placeholder: { type: String },
    maxlength: { type: Number },
    value: {}
  },
  data () {
    return {
      rawValue: tryToParseValue(this.value)
    }
  },
  watch: {
    value () {
      this.rawValue = tryToParseValue(this.value)
    }
  },
  methods: {
    valueChanged () {
      this.$emit('input', formatOutputValue(this.rawValue))
    },
    clearDateTime () {
      this.rawValue = null
      this.valueChanged()
    }
  }
}
</script>
