import toastService from '../services/toast.service'
import local from '../database/local-storage'
const axios = require('axios').default
let onConnectionFailsCb = () => {}

const expiredSessionCallbacks = []
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60 * 1000
})
const silenceInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60 * 1000
})

export const setToken = async token => {
  if (!instance.defaults.headers) {
    instance.defaults.headers = {}
    silenceInstance.defaults.headers = {}
  }
  if (token) {
    instance.defaults.headers.Authorization = `Bearer ${token}`
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
    silenceInstance.defaults.headers.Authorization = `Bearer ${token}`
    silenceInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
    await local.setItem('token', token)
  } else {
    await local.removeItem('token')
    delete instance.defaults.headers.Authorization
    delete silenceInstance.defaults.headers.Authorization
  }
}

// local.getItem('token').then(item => setToken(item))

instance.interceptors.response.use(result => {
  onConnectionFailsCb(false)
  return result.data || {}
}, error => {
  const errorMessage = error.response && error.response.data && error.response.data.error
  let errorToShow
  if (error.response && error.response.status === 404) {
    errorToShow = 'Element not found'
  } else if (error.response && error.response.status === 401) {
    errorToShow = errorMessage || 'Session expired or without permissions'
    expiredSessionCallbacks.forEach(callback => callback())
  } else if (error.message && error.message.toLowerCase().indexOf('network') >= 0) {
    errorToShow = 'Network error'
  } else {
    errorToShow = (error.response && error.response.data && error.response.data.error) || error
  }
  toastService.error(errorToShow)
  return null
})

silenceInstance.interceptors.response.use(result => {
  onConnectionFailsCb(false)
  return result.data || {}
}, error => {
  if (error.response && error.response.status !== 401) {
    onConnectionFailsCb(true)
  }
  return null
})

const buildInstanceService = (axiosInstance, endpoint, id) => {
  const getAll = (params, config) => axiosInstance.get(endpoint, { params, ...config })
  const getOne = id => axiosInstance.get(`${endpoint}/${id}`)
  const create = model => axiosInstance.post(`${endpoint}`, model)
  const update = model => axiosInstance.put(`${endpoint}/${model[id]}`, model)
  const save = model => model[id] ? update(model) : create(model)
  const delet = model => axiosInstance.delete(`${endpoint}/${model[id]}`, model)
  return {
    getAll,
    getOne,
    create,
    update,
    save,
    delete: delet
  }
}

export const buildService = (endpoint, id = 'id') => {
  return {
    ...buildInstanceService(instance, endpoint, id),
    silence: buildInstanceService(silenceInstance, endpoint, id)
  }
}

export const addExpiredSessionEventListener = (callback) => {
  expiredSessionCallbacks.push(callback)
}

export const onConnectionFails = (cb) => {
  onConnectionFailsCb = cb
}

export default instance
