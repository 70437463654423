<template lang="pug">
.command
  .command-icon
    icon(:icon='commandStatusIcon[command.status]' :class='{ spinner: isPending }')
  .comand-details
    p {{ command.deviceName }} - {{ command.name || commandTypeVerb[command.type] }}
    p
      b-tooltip(:label='command.start | datetime' position='is-bottom')
        small {{ command.start | dateDistance }}
      small.ml-1(v-if='command.userName') - por {{ command.userName }}
    small(:class='{ "has-text-danger": isCancelling }') {{ commandStatusVerb[command.status] }}
  .command-actions
    b-button(size='is-small' type='is-danger' rounded v-if='isCancellable' @click='cancel' :loading='command.cancelling')
      icon(icon='times')
</template>

<script>
import { commandStatusEnum, commandStatusIcon, commandTypeVerb, commandStatusVerb } from '@/constants/enums'
import commandsService from '@/services/commands.service'
import toastService from '@/services/toast.service'

export default {
  props: {
    command: { type: Object, required: true }
  },
  data () {
    return {
      commandStatusIcon,
      commandTypeVerb,
      commandStatusVerb
    }
  },
  computed: {
    status () {
      return this.command.status
    },
    isPending () {
      return [
        commandStatusEnum.CANCEL,
        commandStatusEnum.PENDING,
        commandStatusEnum.QUEUED,
        commandStatusEnum.DISPATCHED
      ].includes(this.status)
    },
    isCancelling () {
      return this.status === commandStatusEnum.CANCEL
    },
    isCancellable () {
      return ![
        commandStatusEnum.OTHER_QUEUE,
        commandStatusEnum.OK,
        commandStatusEnum.CANCELED,
        commandStatusEnum.CANCEL
      ].includes(this.status)
    }
  },
  methods: {
    async cancel () {
      if (!await toastService.confirm('¿Desea intentar cancelar el comando?')) return
      this.command.cancelling = true
      const command = {
        ...this.command,
        status: commandStatusEnum.CANCEL
      }
      if (await commandsService.save(command)) {
        this.command.status = commandStatusEnum.CANCEL
      }
      this.command.cancelling = false
    }
  }
}
</script>

<style lang="scss" scoped>
.command {
  display: grid;
  grid-template-columns: 40px auto 40px;
  margin-bottom: 10px;
}

.command-icon,
.command-actions,
.command-actions {
  display: flex;
  align-items: center;
}
.command-actions {
  justify-content: flex-end;
}

.comand-details {
  line-height: 1em;
}
</style>
