import clientsService from '../../services/clients.service'

const clientsMapper = (clientsList = []) => {
  return clientsList.map(client => {
    return {
      ...client,
      locations: `${client?.usedLocations || 0} de ${client?.allowedLocations || 0}`,
      rules: `${client?.usedRules || 0} de ${client?.allowedRules || 0}`,
      viewers: `${client?.usedViewers || 0} de ${client?.allowedViewers || 0}`,
      zones: `${client?.usedZones || 0} de ${client?.allowedZones || 0}`
    }
  })
}

const state = () => ({
  loading: false,
  error: null,
  list: []
})

const mutations = {
  setList: (state, list) => {
    state.list = clientsMapper(list)
  },
  setStatus: (state, { loading, error }) => {
    state.loading = loading
    state.error = error
  }
}

const actions = {
  getClients: async ({ commit }, active) => {
    commit('setStatus', { loading: true })
    const result = await clientsService.getAll({ active })
    if (result) {
      commit('setList', result)
      commit('setStatus', { loading: false })
    } else {
      commit('setList', [])
      commit('setStatus', { loading: false, error: true })
    }
  }
}

const getters = {
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
